import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import FadeWrapper from '../components/fadeWrapper'

import SEO from '../components/seo'
import Cover from '../styles/cover'

const About = styled.div`
  img {
    margin-right: 1.25rem;
    float: left;
    border-radius: 4px;
    box-shadow: 0 1rem 2rem var(--color-shadow);
    width: 600px;
    height: auto;

    @media only screen and (max-width: 950px) {
      display: block;
      width: 100%;
      height: auto;
      float: none;
      margin: 0 auto;
      margin-bottom: 1.25rem;
    }
  }

  p {
    @media only screen and (max-width: 550px) {
      line-height: 1.2;
    }
  }

  &::after {
    content: '';
    display: block;
    clear: both;
  }
`

const AboutPage = ({
  data: {
    markdownRemark: {
      html,
      frontmatter: { title },
    },
  },
}) => (
  <FadeWrapper>
    <SEO title='About' />
    <Cover text='left' key={title}>
      <h1>{title}</h1>
      <About dangerouslySetInnerHTML={{ __html: html }} />
    </Cover>
  </FadeWrapper>
)

export const aboutQuery = graphql`
  query aboutquery {
    markdownRemark(
      fileAbsolutePath: { glob: "**/src/cms/about/about-page.md" }
    ) {
      frontmatter {
        title
      }
      html
    }
  }
`

export default AboutPage
